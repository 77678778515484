<template>
  <v-theme-provider dark>
    <section id="hero-alt">
      <base-img
        :height="$vuetify.breakpoint.mdAndUp ? 250 : 175"
        :gradient="gradient"
        :src="src"
        color="#45516b"
        flat
        max-width="100%"
        tile
        :contain="false"
      >
        <v-row
          v-if="title"
          align="center"
          class="ma-0 fill-height text-center"
          justify="center"
        >
          <v-col cols="12">
            <base-heading
              :title="title"
              space="2"
              weight="500"
            />

            <base-divider
              color="primary"
              dense
            />

            <v-breadcrumbs
              :items="items"
              class="justify-center pa-0"
              :large="true"
              divider=">"
            />
          </v-col>
        </v-row>
      </base-img>
    </section>
  </v-theme-provider>
</template>

<script>
  // Components
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'
  import { unslugify } from 'unslugify'
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_ALL } from '@/App.vue'

  export default {
    name: 'SectionSubHero',
    provide: {
      heading: { align: 'center' },
    },

    computed: {
      ...mapState(STORE_NAMESPACE_ALL, [
        'translations',
      ]),
      gradient () {
        // const color = `${this.$vuetify.theme.themes.light.secondary}CC`
        // const overlay = RGBAtoCSS(HexToRGBA(color))
        const overlay = 'rgba(89, 84, 87, 0.7)'

        return `to top, ${overlay}, ${overlay}`
      },
      src () {
        return this.$route.meta.src
      },
      upper () {
        return this.$route.meta.upper
      },
      items () {
        return [
          { text: this.translations.homepage?.toUpperCase(), to: '/' },
          { text: this.unslugify(this.$router.history.current.path) },
        ]
      },
      title () {
        return this.unslugify(this.$route.meta.title)
      },
    },
    methods: {
      unslugify (name) {
        const unslugifiedName = unslugify(name).replace('/', '')
        return this.upper ? unslugifiedName.toUpperCase() : unslugifiedName
      },
    },
  }
</script>

<style lang="sass">
  #hero-alt
    .v-breadcrumbs__item
      color: #FFFFFF
</style>
